import React, { CSSProperties, useEffect, useState } from 'react';
import {useForm} from 'react-hook-form';
import 'bootstrap/dist/css/bootstrap.min.css'
import {Button, Container, Form, FormControl, FormGroup, FormLabel, Row, Col, Alert} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import LoadingOverlay from 'react-loading-overlay';

// Using Selector/Dispatch as against using a connect/mapXxxToProps
import { useDispatch, useSelector} from "react-redux";
import { saveUserProfile } from "../../../Store/ActionCreators";

import { IUserOrg, IUserProfile } from '../../../Models/Users/User';

import './UserProfile.scss';
import { selectUserProfile } from '../../../Store/Selectors';

interface IUserProfileProps {
  isAuthenticated: boolean;
  authButtonMethod: any;
  user: any;
  userProfile: IUserProfile;
  getAccessToken: Function; 
}

// //Need to move to correct location
// const SignupSchema = yup.object().shape({
//   firstName: yup.string().required(),
//   age: yup
//     .number()
//     .required()
//     .positive()
//     .integer(),
//   website: yup.string().url(),
// });

//class UserProfile extends React.Component<UserProfileProps, UserProfileState> {
const UserProfile : React.FC<IUserProfileProps> = (props: IUserProfileProps) => {


  //Old: const userProfile = useSelector(state => state.userProfile);
  //New: const userProfile = useSelector(selectUserProfile);  //Not required?
  const dispatch = useDispatch();

  const {register, handleSubmit, errors} = useForm();
  
  //const [updatedProfile, setUpdatedProfile] = useState({});
  //const onSubmit = (data:any) => console.log("Data", data);
  const onSubmit = async (data:any) => {
    console.log("Data", data);
    //setUpdatedProfile(data);
    const accessToken = await props.getAccessToken();

    let userProfileToUpdate = {...props.userProfile}
    userProfileToUpdate.firstName = data.firstName;
    userProfileToUpdate.lastName = data.lastName;
    if(!userProfileToUpdate.userOrganisation) {
      userProfileToUpdate.userOrganisation = {} as IUserOrg;
    }    
    let userOrganisation: any = userProfileToUpdate.userOrganisation ? {...userProfileToUpdate.userOrganisation} : {};

    userOrganisation.mailingAddress = {...userOrganisation.mailingAddress,
      userOrgName: data.orgName,
      abn: data.abn,
      fullAddress: data.postalAddress,
      postCode: data.postCode,
      state: data.state
    } as any;

    userProfileToUpdate.userOrganisation = userOrganisation;

    //If Schema is validated, we need to set the metedata to prevent user redirection to profile page
    if(!userProfileToUpdate.metaData) {
      userProfileToUpdate.metaData = {};
    }
    userProfileToUpdate.metaData.hasSetupOrganisationProfile = true;

    (userProfileToUpdate.userOrganisation as any).mailingAddress.state = state;
    console.log('userProfileToUpdate', userProfileToUpdate)
    dispatch(saveUserProfile(accessToken, userProfileToUpdate));
  }

  const userProfileOrg = props.userProfile.userOrganisation ? props.userProfile.userOrganisation : null;

  const mailingAddress = (userProfileOrg && userProfileOrg.mailingAddress) ? userProfileOrg.mailingAddress : null;

  const [state, setMailingState] = useState('');

  useEffect(() => {
    setMailingState(mailingAddress?.state || '');
    console.log('mailingAddress', mailingAddress)
  }, [mailingAddress]);

  const userProfileMetaData = (props.userProfile && props.userProfile.metaData) ? props.userProfile.metaData : null;
  //const showBanner = (props.isAuthenticated && (Object.keys(userProfile).length === 0));

  const buttonColStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: 'auto',
    alignItems: 'flex-end'
  };

  const columns : any[] = [
    {
      dataField: 'firstName',
      text: 'First Name'
    },
    {
      dataField: 'lastName',
      text: 'Last Name'
    },
    {
      dataField: 'email',
      text: 'Email'
    },
    {
      dataField: 'role',
      text: 'Role'
    },
  ];

  const users = props.userProfile.orgUsers?.length ?
    props.userProfile.orgUsers
      .filter((user: IUserProfile) =>
        user._id !== props.userProfile._id
      )
      .map((user: IUserProfile) => ({
        ...user,
        role: (user?.roles) ? 
          (user?.roles.length ? user.roles[0].roleName : '') 
          : ''
      }))
  : [];

  const onStateChanged = (state: string) => {
    setMailingState(state);
  };  

  const userProfile = useSelector(selectUserProfile);

  return (
    <LoadingOverlay className="row" active={!Object.keys(userProfile).length} spinner text='Please wait...'>
      <Container fluid>
        <Row>
          <Col className="mt-2">
            {(userProfileMetaData && !userProfileMetaData.hasSetupOrganisationProfile) ? 
            <Alert variant="primary"> 
              <div>Hi, you have been redirected here as you have not setup your organisation profile yet.</div>
              <div>Please setup your organisation name and click on 'Update Profile' to stop being redirected here.</div>
            </Alert> : ""
            }            
          </Col>          
        </Row>
        <Row className={"mx-5 my-5"}>
          {(props.isAuthenticated && props.userProfile ?
            <Form onSubmit={handleSubmit(onSubmit)} className="col-12">
              <Row>
                <Col xs={12} sm={6} md={4} lg={3}>
                  <FormGroup>
                    <FormLabel>Email:</FormLabel>
                    <FormControl id="email" as="input" disabled name="email" value={props.userProfile.email} ref={register}></FormControl>
                  </FormGroup>
                </Col>

                <Col xs={12} sm={6} md={4} lg={3}>
                  <FormGroup>
                    <FormLabel>Mobile:</FormLabel>
                    <FormControl id="mobile" as="input" disabled name="mobile" 
                        value={props.userProfile?.userOrgContacts && props.userProfile?.userOrgContacts.length > 0 ? props.userProfile.userOrgContacts[0].mobile : "" }
                        ref={register}></FormControl>
                  </FormGroup>
                </Col>

               
              </Row>
              <Row>
                <Col xs={12} sm={6} md={4} lg={3}>
                  <FormGroup>
                    <FormLabel>First Name:</FormLabel>
                    <FormControl id="firstName" as="input" name="firstName" defaultValue={props.userProfile.firstName} ref={register}></FormControl>
                  </FormGroup>
                </Col>

                <Col xs={12} sm={6} md={4} lg={3}>
                  <FormGroup>
                    <FormLabel>Last Name:</FormLabel>
                    <FormControl id="lastName" as="input" name="lastName" defaultValue={props.userProfile.lastName} ref={register}></FormControl>
                  </FormGroup>
                </Col>

                <Col xs={12} sm={6} md={4} lg={3}>
                  <FormGroup>
                    <FormLabel>Organisation Name:</FormLabel>
                    <FormControl id="orgName" as="input" name="orgName" defaultValue={userProfileOrg? userProfileOrg.userOrgName : ""} ref={register}></FormControl>
                  </FormGroup>
                </Col>          

                <Col xs={12} sm={6} md={4} lg={3}>
                  <FormGroup>
                    <FormLabel>ABN:</FormLabel>
                    <FormControl id="abn" as="input" name="abn" defaultValue={userProfileOrg ? userProfileOrg.abn : ""} ref={register}></FormControl>
                  </FormGroup>
                </Col>          

                <Col xs={12} sm={6} md={8} lg={6}>
                  <FormGroup>
                    <FormLabel>Postal Address:</FormLabel>
                    <FormControl id="postalAddress" as="input" name="postalAddress" defaultValue={ mailingAddress ? mailingAddress.fullAddress : ""} ref={register}></FormControl>
                  </FormGroup>
                </Col>          

                <Col xs={12} sm={6} md={4} lg={2}>
                  <Form.Group>
                    <Form.Label>Postcode</Form.Label>
                    <Form.Control id="postCode" type="text" name="postCode" placeholder="Enter Postcode" defaultValue={ mailingAddress ? mailingAddress.postCode : ""} ref={register}/>
                  </Form.Group>
                </Col>
              
                <Col xs={12} sm={6} md={4} lg={2}>
                  <Form.Group>
                    <Form.Label>State</Form.Label>
                    <Form.Control id="state" as="select" name="state" value={state} onChange={(e) => onStateChanged(e.target.value)}>
                      <option>Select State</option>
                      <option value="Australian Capital Territory">Australian Capital Territory</option>
                      <option value="Northern Territory">Northern Territory</option>
                      <option value="New South Wales">New South Wales</option>
                      <option value="Queensland">Queensland</option>
                      <option value="South Australia">South Australia</option>
                      <option value="Tasmania">Tasmania</option>
                      <option value="Victoria">Victoria</option>
                      <option value="Western Australia">Western Australia</option>
                    </Form.Control>
                  </Form.Group>
                </Col>

                <Col xs={12} sm={12} md={12} lg={12} style={buttonColStyle}>
                  <Button className="pull-right" type="submit" variant="success">Update Profile</Button>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col className="border-top border-dark">
                  <h5 className="my-3">Organisation Users</h5>

                  <BootstrapTable
                      keyField="id"
                      data={ users }
                      columns={columns}
                      noDataIndication="No records found"
                      classes="table table-bordered table-striped table-sm"
                  />
                  </Col>
              </Row>
            </Form>
          : "")}
        </Row>
        <Row>
          <Col>
              {/* <UserProfileContent {...props} /> */}
            {(!props.isAuthenticated ?
            <React.Fragment> 
              <Button color="primary" onClick={props.authButtonMethod}>Please Sign-in to view profile</Button> 
            </React.Fragment> 
            : "")}
          </Col>          
        </Row>        
      </Container>
    </LoadingOverlay>
  );
}

// const UserProfileContent = (props: IUserProfileProps) => {

//   // If authenticated, greet the user
//   const userProfile = (props.userProfile) ? props.userProfile : {} as IUser;
  
//   if(props.isAuthenticated && Object.keys(userProfile).length > 0) {

//     const userName = `${userProfile.firstName} ${userProfile.lastName}`;

//     return (
//       <div>
//         <h4>Profile Details (WIP: To be retrieved from graph api?)</h4>
//         <p>User profile: {JSON.stringify(userProfile)}</p>
//         <p>Name: {userName}</p>
//         <p>Email: {userProfile.email}</p>
//       </div>
//     );
//   }
//   else{
//     // Not authenticated, present a sign in button  
//     return (
//       <Button color="primary" onClick={props.authButtonMethod}>Please Sign-in to view profile</Button>
//     );
//   }
// }  

export default UserProfile;