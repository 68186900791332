import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import {Jumbotron} from 'react-bootstrap';

interface Props {}

interface State {
  isOpen: boolean //KS: Can define any specific state params
}

class About extends React.Component<Props, State> {

  constructor(props: Props, state: State) {
    super(props);

    //KS: Set default state params as required
    this.state = {
      isOpen: false
    };
  }

  render() {
    return (
      <Jumbotron>
        <h4>About Page</h4>
        <br/>
        <p>One place to serve all your needs related to Greening Australia!</p>
        <p>If you need to contact us or you need support please email us on <a href="mailto:support@gerx.org.au">support@gerx.org.au</a></p>
        <small>Version 0.0.1</small>
      </Jumbotron>
    );
  }
}

export default About;
